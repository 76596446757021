<template>
    <div id="MultipurposeRoom">
        <div class="vx-card p-6">
            <div class="vx-row w-full">
                <div class="vx-col mb-5 ml-1 md:w-2/6 ">
                    <select v-model="selectedView" class="btnOptions">
                        <option v-for="(options, index) in viewModeOptions" :value="options.value" :key="index">
                            {{ options.title }}
                        </option>
                    </select>

                    <span @click="onClickNavi($event)">
      <button type="button" class="btn btn-default btn-sm move-today" data-action="move-today">Hôm nay</button>
      <button type="button" class="btn btn-default btn-sm move-day" data-action="move-prev">Trước</button>
      <button type="button" class="btn btn-default btn-sm move-day" data-action="move-next">Sau</button>
      </span>
                    <span class="render-range">{{ dateRange }}</span>

                </div>

                <div class="vx-col md:w-2/6">
                    <vs-button
                        @click="manageMeetingRoom"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                    >
                        Quản lí phòng họp
                    </vs-button>
                </div>
                <div class="vx-col md:w-2/6 ">
                    <vs-button
                        @click="bookAMeeting"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                    >
                        Lịch họp
                    </vs-button>
                </div>
            </div>
            <calendar style="height: 800px"
                      ref="tuiCal"
                      :useDetailPopup="useDetailPopup"
                      :view="selectedView"
                      :calendars="calendarList"
                      :schedules="scheduleList"
                      :taskView="false"
                      :scheduleView="true"
                      :month="month"
                      :week="week"
                      :disableDblClick="disableDblClick"
                      :isReadOnly="isReadOnly"
                      @clickSchedule="onClickSchedule"
                      @clickDayname="onClickDayname"
                      @beforeDeleteSchedule="onBeforeDeleteSchedule"
                      @afterRenderSchedule="onAfterRenderSchedule"

            />
        </div>
    </div>

</template>
<script>
import 'tui-time-picker/dist/tui-time-picker.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-calendar/dist/tui-calendar.css';
import {Calendar} from '@toast-ui/vue-calendar';

const today = new Date();
const getDate = (type, start, value, operator) => {
    start = new Date(start);
    type = type.charAt(0).toUpperCase() + type.slice(1);
    if (operator === '+') {
        start[`set${type}`](start[`get${type}`]() + value);
    } else {
        start[`set${type}`](start[`get${type}`]() - value);
    }
    return start;
};
export default {
    name: 'App',
    components: {
        'calendar': Calendar
    },
    data() {
        return {
            viewModeOptions: [
                {
                    title: 'Tháng',
                    value: 'month'
                },
                {
                    title: 'Tuần',
                    value: 'week'
                },
                {
                    title: 'Ngày',
                    value: 'day'
                }
            ],
            dateRange: '',
            selectedView: 'day',
            calendarList: [],
            scheduleList: [],
            month: {
                startDayOfWeek: 0
            },
            week: {
                showTimezoneCollapseButton: true,
            },
            taskView: true,
            scheduleView: true,
            useDetailPopup: true,
            disableDblClick: true,
            isReadOnly: true
        };
    },
    created() {
        this.initScreen();
    },
    watch: {
        selectedView(newValue) {
            this.$refs.tuiCal.invoke('changeView', newValue, true);
            this.setRenderRangeText();
        }
    },
    methods: {
        manageMeetingRoom() {
            let url = '/user/manage-meeting-room';
            if (this.branchId)
                url = `${url}`;
            this.$router.push(url).catch(() => {
            })
        },
        bookAMeeting() {
            let url = '/user/meeting-schedule';
            if (this.branchId)
                url = `${url}`;
            this.$router.push(url).catch(() => {
            })
        },
        initScreen() {

            this.$vs.loading();
            this.$crm.get('/reservation/all').then((response) => {
                this.scheduleList = response.data.map(item => {
                    if (item.capacity == null) {
                        item.capacity = '0';
                    }
                    if (item.reservationName == null) {
                        item.reservationName = 'Không có tên cuộc họp';
                    }
                    if (item.purpose == null) {
                        item.purpose = '';
                    }
                    return {
                        title: item.reservationName,
                        body: 'Mục đích: ' + item.purpose + '.',
                        location: "Tầng 8, HL Tower, Duy Tân, Hà Nội.",
                        category: 'time',
                        dueDateClass: "",
                        recurrenceRule: 'Người tham dự: ' + item.participants.map(i => {
                            return i.fullName
                        }) + '.',
                        start: new Date(item.dateBegin),
                        end: new Date(item.dateEnd),
                        borderColor: 'red',
                        bgColor: '#059862a8',
                        state: 'Số người tham dự: ' + item.capacity + ' người.'

                    }
                });
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        init() {
            this.setRenderRangeText();
        },
        setRenderRangeText() {
            const {invoke} = this.$refs.tuiCal;
            const view = invoke('getViewName');
            const calDate = invoke('getDate');
            const rangeStart = invoke('getDateRangeStart');
            const rangeEnd = invoke('getDateRangeEnd');
            let year = calDate.getFullYear();
            let month = calDate.getMonth() + 1;
            let date = calDate.getDate();
            let dateRangeText = '';
            let endMonth, endDate, start, end;
            switch (view) {
                case 'month':
                    dateRangeText = `${year}-${month}`;
                    break;
                case 'week':
                    year = rangeStart.getFullYear();
                    month = rangeStart.getMonth() + 1;
                    date = rangeStart.getDate();
                    endMonth = rangeEnd.getMonth() + 1;
                    endDate = rangeEnd.getDate();
                    start = `${year}-${month}-${date}`;
                    end = `${endMonth}-${endDate}`;
                    dateRangeText = `${start} ~ ${end}`;
                    break;
                default:
                    dateRangeText = `${year}-${month}-${date}`;
            }
            this.dateRange = dateRangeText;
        },
        onClickNavi(event) {
            if (event.target.tagName === 'BUTTON') {
                const {target} = event;
                let action = target.dataset ? target.dataset.action : target.getAttribute('data-action');
                action = action.replace('move-', '');
                this.$refs.tuiCal.invoke(action);
                this.setRenderRangeText();
            }
        },
        onClickSchedule(res) {
            console.group('onClickSchedule');
            console.log('MouseEvent : ', res.event);
            console.log('Calendar Info : ', res.calendar);
            console.log('Schedule Info : ', res.schedule);
            console.groupEnd();
        },
        onClickDayname(res) {
            // view : week, day
            console.group('onClickDayname');
            console.log(res.date);
            console.groupEnd();
        },
        onBeforeDeleteSchedule(res) {


            console.group('onBeforeDeleteSchedule');
            console.log('Schedule Info : ', res.schedule);
            console.groupEnd();
            let idx = this.scheduleList.findIndex(item => item.id = res.schedule.__fe_id);
            // this.scheduleList.splice(idx, 1);
            this.$vs.loading();
            this.$crm.delete(`/meeting-room/${this.idx}`).then(() => {
                this.$vs.loading.close();
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        onAfterRenderSchedule(res) {
            console.group('onAfterRenderSchedule');
            console.log('Schedule Info : ', res.schedule);
            console.groupEnd();
        },
    },
    mounted() {
        this.init();
    }
};
</script>
<style scoped>
.tui-full-calendar-icon {
    margin-top: -2px;
}

.tui-full-calendar-section-button .tui-full-calendar-icon {
    margin-right: 4px;
    margin-top: 7px;
}


/**  custom bootstrap - start */
.btn {
    border-radius: 25px;
    border-color: red;
    margin-left: 8px;
    border: 1px;
}

.btn:hover {
    background-color: #51b792;
    color: white;
}

.btn:active {
    background-color: #f9f9f9;
    border: solid 1px #bbb;
    outline: none;
}

.btn:disabled {
    background-color: #f9f9f9;
    border: solid 1px #ddd;
    color: #bbb;
}

.btn:focus:active, .btn:focus, .btn:active {
    outline: none;
}

.open > .dropdown-toggle.btn-default {
    background-color: #fff;
}

.dropdown-menu {
    top: 25px;
    padding: 3px 0;
    border-radius: 2px;
    border: 1px solid #bbb;
}

.dropdown-menu > li > a {
    padding: 9px 12px;
    cursor: pointer;
}

.dropdown-menu > li > a:hover {
    background-color: rgba(81, 92, 230, 0.05);
    color: #333;
}

.bi15 {
    width: 15px;
    height: 15px;
}

/** custom fontawesome - end */

.calendar-icon {
    width: 14px;
    height: 14px;
}

#top {
    height: 49px;
    border-bottom: 1px solid #bbb;
    padding: 16px;
    font-size: 10px;
}

#lnb {
    position: absolute;
    width: 200px;
    top: 49px;
    bottom: 0;
    border-right: 1px solid #d5d5d5;
    padding: 12px 10px;
    background: #fafafa;
}

#right {
    position: absolute;
    left: 200px;
    top: 49px;
    right: 0;
    bottom: 0;
}

#lnb label {
    margin-bottom: 0;
    cursor: pointer;
}

.lnb-new-schedule {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
}

.lnb-new-schedule-btn {
    height: 100%;
    font-size: 14px;
    background-color: #ff6618;
    color: #ffffff;
    border: 0;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
}

.lnb-new-schedule-btn:hover {
    height: 100%;
    font-size: 14px;
    background-color: #e55b15;
    color: #ffffff;
    border: 0;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
}

.lnb-new-schedule-btn:active {
    height: 100%;
    font-size: 14px;
    background-color: #d95614;
    color: #ffffff;
    border: 0;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
}

.lnb-calendars > div {
    padding: 12px 16px;
    border-bottom: 1px solid #e5e5e5;
    font-weight: normal;
}

.lnb-calendars-d1 {
    padding-left: 8px;
}

.lnb-calendars-d1 label {
    font-weight: normal;
}

.lnb-calendars-item {
    min-height: 14px;
    line-height: 14px;
    padding: 8px 0;
}

.lnb-footer {
    color: #999;
    font-size: 11px;
    position: absolute;
    bottom: 12px;
    padding-left: 16px;
}

#menu {
    padding: 16px;
}

#dropdownMenu-calendarType {
    padding: 0 8px 0 11px;
}

#calendarTypeName {
    min-width: 62px;
    display: inline-block;
    text-align: left;
    line-height: 30px;
}

.move-today {
    padding: 0 16px;
    line-height: 30px;
}

.move-day {
    padding: 8px;
}

.render-range {
    padding-left: 12px;
    padding-top: 4px;
    font-size: 19px;
    vertical-align: middle;
}

.dropdown-menu-title .calendar-icon {
    margin-right: 8px;
}

.calendar-bar {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid #eee;
    vertical-align: middle;
}

.calendar-name {
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
}

.schedule-time {
    color: #005aff;
}

#calendar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    top: 64px;
}

/** custom fontawesome */
.fa {
    width: 12px;
    height: 12px;
    margin-right: 2px;
}

.tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator {
    background-color: red;
}

.tui-full-calendar-icon {
    width: 13px;
}

.btn {
    border-radius: 25px;
    border-color: #51b792;
}

.btnOptions {
    border-radius: 25px;
    border-color: honeydew;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
}

.btnOptions:hover {
    background-color: #51b792;
    color: white;
}

</style>
